@import url(https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100&display=swap);
body {
  margin: 0;
  font-family: 
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Jost", sans-serif;
}

html {
  font-size: 62.5%;
}

a {
  text-decoration: none;
  
}

li {
  list-style: none;
}

/* 
 

/* navbar style start  */

.main-nav {
  width: 100%;
  height: 9rem;
  display: grid;
  grid-template-columns: 9rem 3fr 4fr  10rem;
  /* box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px; */
}

.logo {
  display: grid;
  /* background-color: #3b5998; */
  grid-column: 2/3;
  justify-content: start;
  align-items: center;
}

#options ul li a:hover
{
  color:red;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}

.menu-link {
  grid-column: 3/4;
}

.menu-link ul {
  height: 10rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.social-media {
  grid-column: 4/5;
}

.social-media ul {
  height: 10rem;
  display: grid;
  grid-template-columns: 3fr repeat(3, 1fr);
  align-items: center;
  justify-content: flex-end;
}

.social-media ul li {
  text-align: right;
}

.social-media ul li:first-child {
  grid-column: 2/3;
}

/* ----------- Grid part ends ----------------- */

.logo h2 {
  font-size: 2.5rem;
  font-weight: 400;
  text-transform: uppercase;
  background: -webkit-linear-gradient(#eee, rgb(69, 57, 248));
  
}

.logo h2 span {
  font-size: 3.5rem;
}

.menu-link ul li {
  font-size: 1.8rem;
}

.menu-link ul li a {
  text-transform: capitalize;
  color: rgba(0, 0, 3, 0.8);
  transition: 0.5s;
}
.menu-link ul li a:hover {
  transform-origin: left;
  color: rgb(219, 32, 32);
  text-decoration: underline;
  transition: 0.5s;
  box-shadow: rgba(83, 26, 69, 0.25) 0px 50px 100px -20px,
  rgba(59, 3, 3, 0.3) 0px 30px 60px -30px;

}

.social-media ul li {
  font-size: 1.8rem;
}

.social-media .hamburger-menu {
  display: none;
}

.facebook {
  color: #3b5998;
}

.instagram {
  color: #c32aa3;
}

.youtube {
  color: #ff0000;
}

/* hero section   */

.hero-section {
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.hero-section p {
  font-size: 3rem;
  text-transform: capitalize;
}

.hero-section h1 {
  font-size: 5rem;
  text-transform: uppercase;
  text-align: center;
}

/* responsive css style  */
@media (max-width: 1080px) {
  .main-nav {
    height: 8rem;
    grid-template-columns: 2rem 3fr 3fr 1fr 2rem;
   
  }

  .logo,
  .menu-link ul,
  .social-media ul {
    height: 8rem;
  }
}

/* responsive css style  */
@media (max-width: 998px) {
  .main-nav {
    height: 7rem;
    grid-template-columns: 2rem 2fr 3fr 2rem 2rem;
  }

  .menu-link {
    display: none;
  }

  .logo,
  .social-media ul {
    height: 7rem;
  }

  .mobile-menu-link {
    grid-column: 1/4;
    position: relative;
    z-index: 99;
    margin-left:20px;
  }

  .mobile-menu-link {
    background-color: rgb(145, 224, 240);
    height: 21rem;
    width:40%;
    display: grid;
    grid-column:2/5 ;
    align-items: right;
    text-align: right;
    margin-left:22rem;
    padding-left: 5rem;
    margin-bottom: 10px;
    padding-bottom: 20px;
    transition: all 2s linear;
    transform-origin: top;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
      rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  }

  .mobile-menu-link ul {
    height: 21rem;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: start;
   
    
  }
  .mobile-menu-link ul li {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
 
  }
  .mobile-menu-link ul li:first-child {
    transition-delay: 0.2s;
  }

  .social-media {
    grid-row: 1/2;
    grid-column: 3/5;
    justify-items: end;
    align-items: center;
    transition: all 2s linear;
  }

  .social-media .social-media-desktop {
    height: 0;
    display: none;
  }

  .social-media {
    height: 7rem;
    display: flex;
    justify-self: end;
    align-items: center;
  }

  .social-media .hamburger-menu {
    display: block;
    font-size: 2.5rem;
  }
}

@media (max-width: 798px) {
  .main-nav {
    height: 6rem;
    grid-template-columns: 1rem 2fr 1fr 1fr 1rem;
  }

  .logo,
  .social-media ul {
    height: 6rem;
  }

  .social-media {
    height: 6rem;
    display: flex;
    justify-self: end;
    align-items: center;
  }

  .social-media .hamburger-menu {
    display: block;
    font-size: 2.5rem;
  }
}

@media (max-width: 520px) {
  .main-nav {
    margin-top:5px;
    height: 8rem;
    grid-template-columns: 1rem 4fr 1fr 1fr 1rem;
    top:0;
  }

  .logo,
  .social-media ul {
    height: 8rem;
  }

  .logo h2 {
    font-size: 2rem;
  }

  .social-media {
    height: 6rem;
    display: flex;
    justify-self: end;
    align-items: center;
  }

  .social-media .hamburger-menu {
    display: block;
    font-size: 2.5rem;
  }

  /* hero section  */

  .hero-section h1 {
    font-size: 3.8rem;
  }
}

.navbarSupportedContent ul li
{
 width:20%;
}

li {
    float: left;
  }
  
  a {
    display: block;
    color:rgb(8, 185, 185)
  }

  .container {
    display: flex;
    flex-direction: column;
  }

  @media screen and (max-width: 1300px) {
    .top_Header{
      display: none;
    
      
    }

    .social-media1 ul li {
      font-size: 1.8rem;
      width:'50%';
      margin-bottom: 10px;
    }

  }

  .facebook1 {
    color: #e8e9eb;
height:30px  }

.instagram {
  color: #c32aa3;
  height:30px
}

.youtube {
  color: #ff0000;
  height:30px
}
.social-media1 ul li {
  font-size: 1.8rem;
}

@media (max-width: 1080px) {
 

 
  .social-media1 ul {
    height: 8rem;
  }

  .social-media1 {
    grid-row: 1/2;
    grid-column: 3/5;
    justify-items: end;
    align-items: center;
    transition: all 2s linear;
  }
  .social-media1 ul li a{
padding-left: 10px;
  }

}



.social-media1 ul {
  height: 10rem;
  display: grid;
  grid-template-columns: 3fr repeat(3, 1fr);
  align-items: center;
  justify-content: flex-end;
  padding-left: 360px;
}

.social-media1 ul li {
  text-align: right;
  padding-left:30px;
  
}

.social-media1 ul li:first-child {
  grid-column: 2/3;
  padding-left: px;
}
  
.Item1 {
    font-family: sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
  
  .seperator {
    width: 100%;
  }
  
  .controls-wrapper {
    width: 100%;
  }
  
  .carousel-wrapper {
    width: 100%;
  }
  
  .rec-carousel-item:focus {
    outline: none;
    box-shadow: inset 0 0 1px 0px violet;
  }
  
