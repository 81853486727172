.navbarSupportedContent ul li
{
 width:20%;
}

li {
    float: left;
  }
  
  a {
    display: block;
    color:rgb(8, 185, 185)
  }

  .container {
    display: flex;
    flex-direction: column;
  }

  @media screen and (max-width: 1300px) {
    .top_Header{
      display: none;
    
      
    }

    .social-media1 ul li {
      font-size: 1.8rem;
      width:'50%';
      margin-bottom: 10px;
    }

  }

  .facebook1 {
    color: #e8e9eb;
height:30px  }

.instagram {
  color: #c32aa3;
  height:30px
}

.youtube {
  color: #ff0000;
  height:30px
}
.social-media1 ul li {
  font-size: 1.8rem;
}

@media (max-width: 1080px) {
 

 
  .social-media1 ul {
    height: 8rem;
  }

  .social-media1 {
    grid-row: 1/2;
    grid-column: 3/5;
    justify-items: end;
    align-items: center;
    transition: all 2s linear;
  }
  .social-media1 ul li a{
padding-left: 10px;
  }

}



.social-media1 ul {
  height: 10rem;
  display: grid;
  grid-template-columns: 3fr repeat(3, 1fr);
  align-items: center;
  justify-content: flex-end;
  padding-left: 360px;
}

.social-media1 ul li {
  text-align: right;
  padding-left:30px;
  
}

.social-media1 ul li:first-child {
  grid-column: 2/3;
  padding-left: px;
}
  